const FastestAnswer = ({ fastestAnswer }) => (
    <div className="fastest-answer">
      <h3>Fastest Correct Answer</h3>
      {fastestAnswer ? (
        <div>
          <p>{fastestAnswer.username}</p>
          <p>{fastestAnswer.timeTaken}</p>
        </div>
      ) : (
        <p>No correct answers yet</p>
      )}
    </div>
  );

  export default FastestAnswer;