import React from 'react';

const GameProgress = ({ progress }) => {
  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="game-progress">
      <div className="progress-info">
        <span className="question-count">
          Question {progress.currentQuestion} of {progress.totalQuestions}
        </span>
        <span className="reset-timer">
          Game resets in: {formatTime(progress.timeUntilReset)}
        </span>
      </div>
      <div className="progress-bar-container">
        <div 
          className="progress-bar"
          style={{ 
            width: `${(progress.currentQuestion / progress.totalQuestions) * 100}%`
          }}
        />
      </div>
    </div>
  );
};

export default GameProgress;