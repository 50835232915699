const Options = ({ question, buttonTransition, handleAnswer }) => (
    <div className="options">
      {question?.options.map((option, index) => (
        <button
            key={index}
            className={`button ${buttonTransition}`}
            onClick={(e) => handleAnswer(option.isCorrect, index, option.id, e)}
        >
            {option.text}
      </button>
      ))}
    </div>
  );

  export default Options;