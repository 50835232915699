import React, { useState, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

const QuestionReview = () => {
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    fetchUnreviewedQuestions();
  }, []);
  
  const fetchUnreviewedQuestions = async () => {
    const response = await fetch('http://localhost:4000/api/unreviewed-questions');
    const data = await response.json();
    setQuestions(data);
  };
  
  const reviewQuestion = async (reviewed) => {
    if (!questions[currentIndex]) return;
    
    console.log('Attempting to review question:', {
      questionId: questions[currentIndex].id,
      reviewed,
      currentIndex,
      totalQuestions: questions.length
    });

    try {
      console.log('Sending review:', { id: questions[currentIndex].id, reviewed });
      
      const response = await fetch(`http://localhost:4000/api/review-question/${questions[currentIndex].id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reviewed })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to update question: ${errorData.error}`);
      }
  
      const data = await response.json();
      console.log('Server response:', data);
  
      // Remove reviewed question and move to next
      setQuestions(questions.filter((_, i) => i !== currentIndex));
      if (currentIndex >= questions.length - 1) {
        setCurrentIndex(0);
      }
    } catch (error) {
      console.error('Error in reviewQuestion:', error);
    }
  };
  
  // Hotkey bindings
  useHotkeys('1', () => reviewQuestion(true));  // approve
  useHotkeys('2', () => reviewQuestion(false)); // deny
  useHotkeys('3', () => reviewQuestion(null));  // skip
  
  if (questions.length === 0) {
    return <div>No questions to review!</div>;
  }
  
  const currentQuestion = questions[currentIndex];
  
  return (
    <div className="review-container">
      <h2 className="review-title">Question Review Mode</h2>
      {currentQuestion ? (
        <>
          <div className="review-question-container">
            <p className="review-counter">Question {currentIndex + 1} of {questions.length}</p>
            <p className="review-question-text">{currentQuestion.text}</p>
          </div>

          <div className="review-options-container">
            {currentQuestion.options.map((option) => (
              <div key={option.id} className="review-option">
                {option.text}
              </div>
            ))}
          </div>

          <div className="review-controls">
            <button onClick={() => reviewQuestion(true)} className="review-button approve">
              Approve (1)
            </button>
            <button onClick={() => reviewQuestion(false)} className="review-button deny">
              Deny (2)
            </button>
            <button onClick={() => reviewQuestion(null)} className="review-button skip">
              Skip (3)
            </button>
          </div>
        </>
      ) : (
        <p className="no-questions">No more questions to review!</p>
      )}
    </div>
  );
};

export default QuestionReview;