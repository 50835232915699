import React from 'react';

const AnswerStats = ({ stats }) => {
  if (!stats || !stats.totalAnswers) return null;

  return (
    <div className="answer-stats">
      <h3>Current Question Stats</h3>
      <div className="stat-item">
        <span>Correct Answers:</span>
        <span>{stats.correctPercentage.toFixed(1)}%</span>
      </div>
      <div className="stat-item">
        <span>Total Answers:</span>
        <span>{stats.totalAnswers}</span>
      </div>
    </div>
  );
};

export default AnswerStats;