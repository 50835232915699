// Confetti.js
import React from 'react';
import confetti from 'canvas-confetti';

const Confetti = ({ position }) => {
  React.useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { 
        x: position.x / 100,
        y: position.y / 100
      }
    });
  }, [position]);

  return null;
};

export default Confetti;