import React, { useState, useEffect } from 'react';
import './Game.css';
import QuestionReview from './components/QuestionReview';
import Header from './components/Header';
import UsernameInput from './components/UsernameInput';
import Question from './components/Question';
import Options from './components/Options';
import FastestAnswer from './components/FastestAnswer';
import Leaderboard from './components/Leaderboard';
import Timer from './components/Timer';
import AnswerStats from './components/AnswerStats';
import GameProgress from './components/GameProgress';
import Confetti from './components/Confetti';

const Game = ({ socket }) => {
  const [username, setUsername] = useState(null);
  const [enteredUsername, setEnteredUsername] = useState("");
  const [question, setQuestion] = useState(null);
  const [timer, setTimer] = useState(8000);
  const [score, setScore] = useState(0);
  const [leaderboard, setLeaderboard] = useState({});
  const [hasAnswered, setHasAnswered] = useState(false);
  const [fastestAnswer, setFastestAnswer] = useState(null);
  const [questionTransition, setQuestionTransition] = useState('fade-in');
  const [buttonTransition, setButtonTransition] = useState('fade-in');
  const [showReviewMode, setShowReviewMode] = useState(false);
  const isDevelopment = process.env.NODE_ENV === 'development';
  const [answerStats, setAnswerStats] = useState(null);
  const [gameProgress, setGameProgress] = useState({
    currentQuestion: 0,
    totalQuestions: 0,
    timeUntilReset: 0
  });
  const [confettiPosition, setConfettiPosition] = useState(null);

  const joinGame = () => {
    if (enteredUsername) {
      setUsername(enteredUsername);
      socket.emit('setUsername', { username: enteredUsername });
    }
  };

  const handleAnswer = (isCorrect, index, optionId, event) => {
    if (hasAnswered || timer <= 0) return;
    
    setHasAnswered(true);
    if (isCorrect) {
      setConfettiPosition({ 
        x: event.clientX / window.innerWidth * 100,
        y: event.clientY / window.innerHeight * 100 
      });
    }
    const buttons = document.getElementsByClassName('button');
    
    if (isCorrect) {
      buttons[index].classList.add('button-correct');
    } else {
      buttons[index].classList.add('button-incorrect');
    }

    const correctIndex = question.options.findIndex(option => option.isCorrect);
    if (correctIndex !== -1) {
      buttons[correctIndex].classList.add('button-correct');
    }

    socket.emit('answer', { isCorrect, optionId });
  };

  useEffect(() => {
    const handlers = {
      newQuestion: (newQuestion) => {
        setQuestionTransition('fade-out');
        setButtonTransition('fade-out');
        setConfettiPosition(null);

        const buttons = document.getElementsByClassName('button');
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].classList.remove('button-correct', 'button-incorrect');
        }

        setTimeout(() => {
          setQuestion(newQuestion);
          setTimer(8000);
          setHasAnswered(false);
          setFastestAnswer(null);
          setQuestionTransition('fade-in');
          setButtonTransition('fade-in');
        }, 1000);
      },
      leaderboardUpdate: (newLeaderboard) => setLeaderboard(newLeaderboard),
      scoreUpdate: (newScore) => setScore(newScore),
      fastestCorrectAnswer: (data) => {
        const timeTakenInSeconds = (data.timeTaken / 1000).toFixed(3);
        setFastestAnswer({
          username: data.username,
          timeTaken: `${timeTakenInSeconds} seconds`
        });
      },
      answerStats: (stats) => setAnswerStats(stats),
      gameProgress: (progress) => setGameProgress(progress)
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      socket.on(event, handler);
    });

    return () => {
      Object.keys(handlers).forEach(event => {
        socket.off(event);
      });
    };
  }, [socket]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer(timer - 100), 100);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <div className="container">
      <Header 
        isDevelopment={isDevelopment}
        showReviewMode={showReviewMode}
        setShowReviewMode={setShowReviewMode}
      />

      {showReviewMode ? (
        <QuestionReview />
      ) : (
        <>
          {username ? (
            <>
              <GameProgress progress={gameProgress} />
              
              <div className="game-main">
                <div className="game-content">
                  <Question 
                    question={question}
                    questionTransition={questionTransition}
                    hasAnswered={hasAnswered}
                    timer={timer}
                  />
                  
                  <Options 
                    question={question}
                    buttonTransition={buttonTransition}
                    handleAnswer={handleAnswer}
                  />

                  {confettiPosition && <Confetti position={confettiPosition} />}

                  {(hasAnswered || timer <= 0) && question?.funFact && (
                    <div className="fun-fact">
                      <h3>Fun Fact</h3>
                      <p>{question.funFact}</p>
                    </div>
                  )}
                </div>

                <div className="game-sidebar">
                  <Timer timer={timer} maxTime={8000} />
                  
                  <div className="info-panel">
                    <h3>Your Score</h3>
                    <p className="score">{score}</p>
                  </div>

                  <FastestAnswer fastestAnswer={fastestAnswer} />
                  
                  <AnswerStats stats={answerStats} />
                  
                  <Leaderboard leaderboard={leaderboard} />
                </div>
              </div>
            </>
          ) : (
            <UsernameInput
              enteredUsername={enteredUsername}
              setEnteredUsername={setEnteredUsername}
              joinGame={joinGame}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Game;