const UsernameInput = ({ enteredUsername, setEnteredUsername, joinGame }) => (
    <div className="username-container">
      <input
        type="text"
        placeholder="Enter your username"
        value={enteredUsername}
        onChange={(e) => setEnteredUsername(e.target.value)}
        className="username-input"
        autoFocus
        onKeyDown={(e) => e.key === 'Enter' && joinGame()}
      />
      <button onClick={joinGame} className="username-button">Join</button>
    </div>
  );

  export default UsernameInput;