const Question = ({ question, questionTransition, hasAnswered, timer }) => (
    <>
      <p className={`question ${questionTransition}`}>{question?.text}</p>
      {(hasAnswered || timer <= 0) && question?.funFact && (
        <div className="fun-fact">
          <h3>Fun Fact</h3>
          <p>{question.funFact}</p>
        </div>
      )}
    </>
  );

  export default Question;