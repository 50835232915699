import React from 'react';

const Timer = ({ timer, maxTime = 8000 }) => {
  const percentage = (timer / maxTime) * 100;
  
  return (
    <div className="timer-container">
      <div className="timer-display">
        {(timer / 1000).toFixed(1)}s
      </div>
      <div className="timer-bar-container">
        <div 
          className="timer-bar" 
          style={{ 
            width: `${percentage}%`,
            backgroundColor: percentage < 30 ? '#E74C3C' : percentage < 60 ? '#F1C40F' : '#2ECC71'
          }} 
        />
      </div>
    </div>
  );
};

export default Timer;