import React from 'react';

const Header = ({ isDevelopment, showReviewMode, setShowReviewMode }) => (
    <div className="header">
      <img src="/favicon3.png" alt="Trivia Tangle" className="logo" />
      {isDevelopment && (
        <button 
          onClick={() => setShowReviewMode(!showReviewMode)}
          className="review-mode-button"
        >
          {showReviewMode ? 'Exit Review Mode' : 'Review Questions'}
        </button>
      )}
    </div>
  );

export default Header;