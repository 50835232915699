const Leaderboard = ({ leaderboard }) => (
    <div className="leaderboard">
      <h3>Leaderboard</h3>
      {Object.entries(leaderboard)
        .sort(([, aScore], [, bScore]) => bScore - aScore)
        .map(([userId, userScore], index) => (
          userScore !== 0 && <p key={index}>{`User ${userId}: ${userScore}`}</p>
        ))
      }
    </div>
  );

  export default Leaderboard;